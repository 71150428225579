.root {
  background: #baf701;
  position: relative;
  color: #baf701b3;
  font-weight: 600;
  font-size: 14px;
  box-shadow: 0px 4px 0px 0px #994e4f00;
  line-height: 17.6px;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre;
  user-select: text;
  text-decoration-line: none;
  border-radius: 6px;
  padding: 7px 16px;
  border: 1px none #baf701;
}
@media (min-width: 600px) {
  .root {
    font-weight: 600;
    font-size: 14px;
    color: #baf701b3;
    padding: 7px 16px;
  }
}
@media (min-width: 900px) {
  .root {
    font-size: 18px;
    line-height: 22.63px;
    padding: 12px 24px;
  }
}
@media (min-width: 1536px) {
  .root {
    font-size: 20px;
    line-height: 25.14px;
    padding: 16px 32px;
  }
}
.rootglobal_ctaLevel_monthly {
  background: #baf701;
}
.root:hover {
  background: #78f701;
  color: #1e1e1ed9;
  border-color: #78f701;
}
