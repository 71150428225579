.header {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: center;
  flex-shrink: 0;
  min-height: 280px;
  background: #324aaf;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 600px) {
  .header {
    overflow: hidden;
  }
}
@media (min-width: 900px) {
  .header {
    height: auto;
  }
}
.headerglobal__112720241500120420240500_override {
  background: #ffffff;
}
.mainContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: var(--token-cTiaoKBgu);
  padding-right: 0px;
  padding-top: 0px;
  background: #ffffff;
  min-width: 0;
  border-bottom: 1px solid var(--token-wrFJtVAO5);
}
@media (min-width: 600px) {
  .mainContainer {
    justify-content: center;
    align-items: center;
    padding-left: var(--token-omGhEM8OI);
  }
}
@media (min-width: 900px) {
  .mainContainer {
    height: auto;
    background: #ffffff;
  }
}
@media (min-width: 1024px) {
  .mainContainer {
    padding-left: var(--token-AmaqypV2P);
  }
}
@media (min-width: 1900px) {
  .mainContainer {
    padding-left: 100px;
  }
}
.mainContainerglobal__112720241500120420240500_override {
  background: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0px 0px 0px var(--token-cTiaoKBgu);
}
.mainContainerglobal_ctaLevel_regular_global_region_geo1_global__112720241500120420240500_override {
  display: none;
}
.texts {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 60%;
  height: auto;
  max-width: 100%;
  background: var(--token-jgohepLVeKvh);
  padding: var(--token-TCakqYrfy) 0px;
}
.texts > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.texts > :global(.__wab_flex-container) > *,
.texts > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.texts > :global(.__wab_flex-container) > picture > img,
.texts > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
@media (min-width: 600px) {
  .texts > :global(.__wab_flex-container) {
    align-items: flex-start;
    justify-content: center;
  }
}
@media (min-width: 900px) {
  .texts {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 900px) {
  .texts > :global(.__wab_flex-container) {
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 900px) {
  .texts > :global(.__wab_flex-container) > *,
  .texts > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .texts > :global(.__wab_flex-container) > picture > img,
  .texts
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 1024px) {
  .texts {
    background: #ffffff;
  }
}
.textsglobal_ctaLevel_annual {
  background: #ffffff;
}
.textsglobal_ctaLevel_annual > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 6px);
  height: calc(100% + 6px);
}
.textsglobal_ctaLevel_annual > :global(.__wab_flex-container) > *,
.textsglobal_ctaLevel_annual
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.textsglobal_ctaLevel_annual > :global(.__wab_flex-container) > picture > img,
.textsglobal_ctaLevel_annual
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 6px;
}
.textsglobal__112720241500120420240500_override {
  width: 60%;
  background: var(--token-D1V5iINMo);
}
.textsglobal__112720241500120420240500_override
  > :global(.__wab_flex-container) {
  align-items: flex-start;
  justify-content: center;
}
.text___25Jih {
  color: var(--token-aLKgcASGV);
  font-family: var(--token-PsTTGOOvU);
  font-weight: 700;
  font-size: var(--token-_MOw8VJgR);
  line-height: var(--token-wmteqkiSA);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 0px;
  letter-spacing: 0px;
  padding-bottom: 4px;
  min-width: 0;
}
@media (min-width: 600px) {
  .text___25Jih {
    font-size: var(--token-OVQ_pKinX);
  }
}
@media (min-width: 900px) {
  .text___25Jih {
    color: var(--token-aLKgcASGV);
    font-size: 32px;
  }
}
@media (min-width: 1024px) {
  .text___25Jih {
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: var(--token-wmteqkiSA);
    color: #303030;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
}
@media (min-width: 1280px) {
  .text___25Jih {
    max-width: 640px;
    padding-bottom: 0px;
  }
}
@media (min-width: 1536px) {
  .text___25Jih {
    font-size: var(--token-hOqCuytDszPG);
  }
}
.textglobal__112720241500120420240500_override___25JiHvTogJ {
  font-weight: 700;
  font-size: var(--token-_MOw8VJgR);
  line-height: var(--token-wmteqkiSA);
  color: var(--token-aLKgcASGV);
  text-align: left;
}
@media (min-width: 1024px) {
  .textglobal_ctaLevel_annual___25JiholFmy {
    padding-bottom: 4px;
  }
}
.text__qBmEl {
  color: #303030e0;
  font-family: var(--token-Hn0tANBmC);
  font-weight: 400;
  font-size: 16px;
  line-height: var(--token-wmteqkiSA);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
}
@media (min-width: 900px) {
  .text__qBmEl {
    font-size: 16px;
    color: var(--token-aLKgcASGV);
    font-family: "Source Sans Pro", sans-serif;
  }
}
@media (min-width: 1024px) {
  .text__qBmEl {
    font-size: 16px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    line-height: 1;
    display: block;
    padding: 0px;
  }
}
@media (min-width: 1280px) {
  .text__qBmEl {
    font-size: var(--token-qaV8obD4f);
    line-height: 1;
    padding: 0px 0px 8px;
  }
}
@media (min-width: 1536px) {
  .text__qBmEl {
    padding-bottom: 8px;
  }
}
.textglobal_ctaLevel_annual__qBmElOlFmy {
  padding-bottom: 0px;
}
.textglobal__112720241500120420240500_override__qBmElvTogJ {
  font-size: 16px;
  line-height: var(--token-wmteqkiSA);
  text-align: left;
  color: #303030e0;
}
@media (min-width: 1024px) {
  .textglobal_ctaLevel_monthly__qBmElu1Vk1 {
    padding-bottom: 8px;
  }
}
@media (min-width: 1024px) {
  .textglobal_ctaLevel_annual__qBmElOlFmy {
    padding-bottom: 8px;
  }
}
.h1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 16px;
  color: #000000d9;
  font-weight: 400;
  text-align: left;
  letter-spacing: normal;
  user-select: none;
  font-family: "Source Sans Pro", sans-serif;
  padding-bottom: 6px;
  padding-top: 0px;
  min-width: 0;
}
@media (min-width: 900px) {
  .h1 {
    display: block;
  }
}
@media (min-width: 1024px) {
  .h1 {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    padding: 0px 0px 17px;
  }
}
@media (min-width: 1280px) {
  .h1 {
    padding-bottom: 17px;
  }
}
@media (min-width: 1536px) {
  .h1 {
    padding-bottom: 17px;
  }
}
.h1global__112720241500120420240500_override {
  display: none;
}
@media (min-width: 1024px) {
  .h1global_ctaLevel_annual {
    padding-bottom: 17px;
  }
}
.buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 0px;
}
.buttons > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.buttons > :global(.__wab_flex-container) > *,
.buttons > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttons > :global(.__wab_flex-container) > picture > img,
.buttons
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
@media (min-width: 600px) {
  .buttons > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 600px) {
  .buttons > :global(.__wab_flex-container) > *,
  .buttons > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .buttons > :global(.__wab_flex-container) > picture > img,
  .buttons
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 900px) {
  .buttons {
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    width: auto;
    height: auto;
  }
}
@media (min-width: 900px) {
  .buttons > :global(.__wab_flex-container) {
    flex-direction: row;
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 900px) {
  .buttons > :global(.__wab_flex-container) > *,
  .buttons > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .buttons > :global(.__wab_flex-container) > picture > img,
  .buttons
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
@media (min-width: 1024px) {
  .buttons {
    padding-top: 0px;
  }
}
@media (min-width: 1024px) {
  .buttons > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: center;
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1024px) {
  .buttons > :global(.__wab_flex-container) > *,
  .buttons > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .buttons > :global(.__wab_flex-container) > picture > img,
  .buttons
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
@media (min-width: 1280px) {
  .buttons > :global(.__wab_flex-container) {
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1280px) {
  .buttons > :global(.__wab_flex-container) > *,
  .buttons > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .buttons > :global(.__wab_flex-container) > picture > img,
  .buttons
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
.buttonsglobal_ctaLevel_monthly > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.buttonsglobal_ctaLevel_monthly > :global(.__wab_flex-container) > *,
.buttonsglobal_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.buttonsglobal_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > picture
  > img,
.buttonsglobal_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 8px;
}
.buttonsglobal_ctaLevel_annual > :global(.__wab_flex-container) {
  align-items: flex-start;
  justify-content: center;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.buttonsglobal_ctaLevel_annual > :global(.__wab_flex-container) > *,
.buttonsglobal_ctaLevel_annual
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.buttonsglobal_ctaLevel_annual > :global(.__wab_flex-container) > picture > img,
.buttonsglobal_ctaLevel_annual
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.buttonsglobal_ctaLevel_pastdue > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.buttonsglobal_ctaLevel_pastdue > :global(.__wab_flex-container) > *,
.buttonsglobal_ctaLevel_pastdue
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.buttonsglobal_ctaLevel_pastdue
  > :global(.__wab_flex-container)
  > picture
  > img,
.buttonsglobal_ctaLevel_pastdue
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 8px;
}
.buttonsglobal__112720241500120420240500_override
  > :global(.__wab_flex-container) {
  align-items: flex-start;
  justify-content: flex-start;
}
@media (min-width: 900px) {
  .buttonsglobal_ctaLevel_monthly {
    width: auto;
    height: auto;
    max-width: 100%;
    flex-direction: row;
    padding: 8px 0px 0px;
  }
}
@media (min-width: 900px) {
  .buttonsglobal_ctaLevel_monthly > :global(.__wab_flex-container) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 900px) {
  .buttonsglobal_ctaLevel_monthly > :global(.__wab_flex-container) > *,
  .buttonsglobal_ctaLevel_monthly
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .buttonsglobal_ctaLevel_monthly
    > :global(.__wab_flex-container)
    > picture
    > img,
  .buttonsglobal_ctaLevel_monthly
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
@media (min-width: 1024px) {
  .buttonsglobal_ctaLevel_monthly > :global(.__wab_flex-container) {
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1024px) {
  .buttonsglobal_ctaLevel_monthly > :global(.__wab_flex-container) > *,
  .buttonsglobal_ctaLevel_monthly
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .buttonsglobal_ctaLevel_monthly
    > :global(.__wab_flex-container)
    > picture
    > img,
  .buttonsglobal_ctaLevel_monthly
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
.clickEventReporter__h3Kc:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.clickEventReporterglobal_ctaLevel_annual__h3KcOlFmy:global(.__wab_instance) {
  display: none;
}
.subscribeNow {
  display: flex;
}
.btn2Home:global(.__wab_instance) {
  transition-property: all;
  transition-duration: 1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
@media (min-width: 900px) {
  .btn2Home:global(.__wab_instance) {
    width: auto;
    height: auto;
  }
}
.btn2Homeglobal_ctaLevel_annual:global(.__wab_instance) {
  display: none;
}
.btn2Homeglobal_ctaLevel_regular:global(.__wab_instance) {
  height: 100%;
}
.btn2Homeglobal__112720241500120420240500_override:global(.__wab_instance) {
  display: block;
}
.clickEventReporter__mI6E:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  display: none;
}
.clickEventReporterglobal_ctaLevel_annual__mI6EolFmy:global(.__wab_instance) {
  display: flex;
}
.clickEventReporterglobal__112720241500120420240500_override__mI6EvTogJ:global(
    .__wab_instance
  ) {
  display: flex;
}
.discoverCollectionBtn {
  color: #ffffff;
  display: none;
}
.discoverCollectionBtnglobal_ctaLevel_monthly {
  color: #ffffff;
}
.discoverCollectionBtnglobal_ctaLevel_monthly > :global(.__wab_flex-container) {
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.discoverCollectionBtnglobal_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > *,
.discoverCollectionBtnglobal_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.discoverCollectionBtnglobal_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > picture
  > img,
.discoverCollectionBtnglobal_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.discoverCollectionBtnglobal_ctaLevel_annual {
  display: flex;
}
.discoverCollectionBtnglobal_ctaLevel_regular {
  color: var(--token-jgohepLVeKvh);
}
.discoverCollectionBtnglobal__112720241500120420240500_override {
  display: flex;
}
@media (min-width: 900px) {
  .discoverCollectionBtnglobal_ctaLevel_monthly
    > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 900px) {
  .discoverCollectionBtnglobal_ctaLevel_monthly
    > :global(.__wab_flex-container)
    > *,
  .discoverCollectionBtnglobal_ctaLevel_monthly
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .discoverCollectionBtnglobal_ctaLevel_monthly
    > :global(.__wab_flex-container)
    > picture
    > img,
  .discoverCollectionBtnglobal_ctaLevel_monthly
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
.btn1Home:global(.__wab_instance) {
  max-width: 100%;
  transition-property: all;
  transition-duration: 1s;
  display: none;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.btn1Homeglobal_ctaLevel_annual:global(.__wab_instance) {
  display: block;
}
@media (min-width: 900px) {
  .btn1Homeglobal_ctaLevel_monthly:global(.__wab_instance) {
    width: auto;
    margin-left: calc(0px + 0px) !important;
    margin-top: calc(0px + 0px) !important;
    margin-right: 0px;
    margin-bottom: 0px;
  }
}
.link {
  margin-top: -8px;
  display: none;
}
@media (min-width: 900px) {
  .link {
    margin-top: 0px;
  }
}
@media (min-width: 1024px) {
  .link {
    margin-top: -8px;
  }
}
@media (min-width: 1280px) {
  .link {
    margin-top: 0px;
  }
}
.linkglobal__112720241500120420240500_override {
  display: none;
}
.imgs {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 40%;
  height: 100%;
  max-width: 100%;
  min-height: 0;
  padding: 0px;
}
@media (min-width: 900px) {
  .imgs {
    width: 100%;
    min-width: 0;
  }
}
.img__m7B4I {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  align-self: auto;
  object-position: left center;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.img__m7B4I > picture > img {
  object-fit: cover;
  object-position: left center;
}
@media (min-width: 600px) {
  .img__m7B4I {
    max-height: 350px;
  }
}
@media (min-width: 900px) {
  .img__m7B4I {
    align-self: auto;
    object-fit: cover;
  }
}
@media (min-width: 900px) {
  .img__m7B4I > picture > img {
    object-fit: cover;
  }
}
.mainContainerTest {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #fb393c;
  min-width: 0;
  padding: var(--token-TCakqYrfy) var(--token-cTiaoKBgu);
  border-bottom: 1px solid var(--token-wrFJtVAO5);
}
@media (min-width: 600px) {
  .mainContainerTest {
    justify-content: center;
    align-items: center;
    padding-left: var(--token-omGhEM8OI);
  }
}
@media (min-width: 900px) {
  .mainContainerTest {
    height: auto;
    background: #ffffff;
  }
}
@media (min-width: 1024px) {
  .mainContainerTest {
    padding-left: var(--token-AmaqypV2P);
  }
}
@media (min-width: 1900px) {
  .mainContainerTest {
    padding-left: 12%;
  }
}
.mainContainerTestglobal__112720241500120420240500_override {
  display: none;
}
.mainContainerTestglobal_ctaLevel_regular_global_region_geo1_global__112720241500120420240500_override {
  display: flex;
}
.texts3 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #fb393c;
  padding: var(--token-TCakqYrfy) 0px;
}
.texts3 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.texts3 > :global(.__wab_flex-container) > *,
.texts3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.texts3 > :global(.__wab_flex-container) > picture > img,
.texts3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
@media (min-width: 600px) {
  .texts3 > :global(.__wab_flex-container) {
    align-items: flex-start;
    justify-content: center;
  }
}
@media (min-width: 900px) {
  .texts3 {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 900px) {
  .texts3 > :global(.__wab_flex-container) {
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 900px) {
  .texts3 > :global(.__wab_flex-container) > *,
  .texts3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .texts3 > :global(.__wab_flex-container) > picture > img,
  .texts3
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
.img__cIcfI {
  object-fit: cover;
  max-width: 100%;
  object-position: center center;
  width: 300px;
  height: 86.75px;
  flex-shrink: 0;
}
.img__cIcfI > picture > img {
  object-fit: cover;
  object-position: center center;
}
.text__nOdHj {
  color: #f2ff00b3;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: var(--token--qjKwCUH1);
  line-height: 21.6px;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  min-width: 0;
}
@media (min-width: 600px) {
  .text__nOdHj {
    font-size: var(--token-OVQ_pKinX);
  }
}
@media (min-width: 900px) {
  .text__nOdHj {
    color: var(--token-aLKgcASGV);
    font-size: 32px;
  }
}
@media (min-width: 1024px) {
  .text__nOdHj {
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: var(--token-wmteqkiSA);
    color: #303030;
  }
}
@media (min-width: 1280px) {
  .text__nOdHj {
    max-width: 640px;
  }
}
@media (min-width: 1536px) {
  .text__nOdHj {
    font-size: var(--token-hOqCuytDszPG);
  }
}
.textglobal_ctaLevel_regular_global_region_geo1_global__112720241500120420240500_override__nOdHjYrjpQRwQByVTogJ {
  font-weight: 300;
  color: var(--token-boGrFwFO91mu);
}
.text__lLwg3 {
  color: #303030e0;
  font-family: var(--token-Hn0tANBmC);
  font-weight: 400;
  font-size: 10px;
  line-height: 12.57px;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  min-width: 0;
}
@media (min-width: 900px) {
  .text__lLwg3 {
    font-size: 16px;
    color: var(--token-aLKgcASGV);
    font-family: "Source Sans Pro", sans-serif;
  }
}
@media (min-width: 1024px) {
  .text__lLwg3 {
    font-size: 20px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
  }
}
@media (min-width: 1280px) {
  .text__lLwg3 {
    font-size: var(--token-qaV8obD4f);
  }
}
.textglobal_ctaLevel_annual__lLwg3OlFmy {
  padding-bottom: 14px;
}
.clickEventReporter__ao0Ic:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
}
.button2:global(.__wab_instance) {
  max-width: 100%;
}
.svg__fVkQu {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.textglobal_ctaLevel_regular_global_region_geo1_global__112720241500120420240500_override__p1Ix4YrjpQRwQByVTogJ {
  color: #ffffff;
  line-height: 20.11px;
}
.svg___1XNhU {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__g7J4H {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 12px;
  color: var(--token-WxvvgatuPYoJ);
  min-width: 0;
  display: none;
}
@media (min-width: 900px) {
  .text__g7J4H {
    display: block;
  }
}
.buttons3 {
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  display: none;
  padding: 0px;
}
.buttons3 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-u8uQ-APga));
  height: calc(100% + var(--token-u8uQ-APga));
}
.buttons3 > :global(.__wab_flex-container) > *,
.buttons3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttons3 > :global(.__wab_flex-container) > picture > img,
.buttons3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-u8uQ-APga);
}
@media (min-width: 600px) {
  .buttons3 > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-u8uQ-APga));
    height: calc(100% + var(--token-u8uQ-APga));
  }
}
@media (min-width: 600px) {
  .buttons3 > :global(.__wab_flex-container) > *,
  .buttons3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .buttons3 > :global(.__wab_flex-container) > picture > img,
  .buttons3
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-u8uQ-APga);
  }
}
@media (min-width: 900px) {
  .buttons3 {
    flex-direction: row;
    padding-top: 8px;
    width: auto;
    height: auto;
  }
}
@media (min-width: 900px) {
  .buttons3 > :global(.__wab_flex-container) {
    flex-direction: row;
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 900px) {
  .buttons3 > :global(.__wab_flex-container) > *,
  .buttons3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .buttons3 > :global(.__wab_flex-container) > picture > img,
  .buttons3
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
@media (min-width: 1024px) {
  .buttons3 {
    padding-top: 8px;
  }
}
@media (min-width: 1024px) {
  .buttons3 > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: center;
  }
}
@media (min-width: 1280px) {
  .buttons3 > :global(.__wab_flex-container) {
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1280px) {
  .buttons3 > :global(.__wab_flex-container) > *,
  .buttons3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .buttons3 > :global(.__wab_flex-container) > picture > img,
  .buttons3
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
.buttons3global_ctaLevel_annual > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.buttons3global_ctaLevel_annual > :global(.__wab_flex-container) > *,
.buttons3global_ctaLevel_annual
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.buttons3global_ctaLevel_annual
  > :global(.__wab_flex-container)
  > picture
  > img,
.buttons3global_ctaLevel_annual
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.buttons3global_ctaLevel_regular_global_region_geo1_global__112720241500120420240500_override {
  display: none;
}
.clickEventReporter__vO0Sp:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.goUnlimitedBtn3 {
  position: relative;
  display: flex;
  margin-bottom: 8px;
  color: var(--token-jgohepLVeKvh);
}
@media (min-width: 1280px) {
  .goUnlimitedBtn3 {
    margin-bottom: 0px;
  }
}
.goUnlimitedBtn3global_ctaLevel_monthly {
  color: #000000;
  font-size: var(--token-jFlfsKM8_wXg);
}
.goUnlimitedBtn3global_ctaLevel_annual {
  display: none;
}
.goUnlimitedBtn3global_ctaLevel_pastdue {
  display: flex;
}
.goUnlimitedBtn3global_ctaLevel_regular_global_region_geo1_global__112720241500120420240500_override {
  display: none;
}
.cyberCtaButton:global(.__wab_instance) {
  max-width: 100%;
}
.imgs3 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 40%;
  height: 100%;
  max-width: 100%;
  min-height: 0;
  padding: 0px;
}
@media (min-width: 900px) {
  .imgs3 {
    width: 100%;
    min-width: 0;
  }
}
.imgs3global_ctaLevel_regular_global_region_geo1_global__112720241500120420240500_override {
  display: none;
}
.img__bVru8 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  align-self: auto;
  object-position: left center;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.img__bVru8 > picture > img {
  object-fit: cover;
  object-position: left center;
}
@media (min-width: 600px) {
  .img__bVru8 {
    max-height: 350px;
  }
}
@media (min-width: 900px) {
  .img__bVru8 {
    align-self: auto;
    object-fit: cover;
  }
}
@media (min-width: 900px) {
  .img__bVru8 > picture > img {
    object-fit: cover;
  }
}
