.homepage {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.pageLayoutComponent:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.websiteSchema:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.headerHomegageBase:global(.__wab_instance) {
  max-width: 100%;
}
.headerHomegageBaseglobal__102920241500110120240500_override:global(
    .__wab_instance
  ) {
  display: none;
}
.headerHomegageBaseglobal__010920251500021020252050_override:global(
    .__wab_instance
  ) {
  display: none;
}
.headerHomegageBaseglobal__021020252100030720251600_override:global(
    .__wab_instance
  ) {
  display: none;
}
.vdayHomepageHeader:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.vdayHomepageHeaderglobal__010920251500021020252050_override:global(
    .__wab_instance
  ) {
  display: flex;
}
.herocardHomepage:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.herocardHomepageglobal__102920241500110120240500_override:global(
    .__wab_instance
  ) {
  display: flex;
}
.herocardHomepageglobal__021020252100030720251600_override:global(
    .__wab_instance
  ) {
  display: flex;
}
.marketingListsComponent:global(.__wab_instance) {
  position: relative;
}
.gridWithGradientButton:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.featureDetailHome__xwGDp:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.aRectangularGridComposedOfMockupsAndDesignsFreePlaceitsTemplatesInSoftNeutralTonesFeaturingAHighlightedDesignWithAColorFreeLabelAtTheTop:global(
    .__wab_instance
  ) {
  width: 640px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.htmlVideo__rfrBp:global(.__wab_instance) {
  width: 640px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.link__uWsjd {
  position: relative;
  background: #c1a06f;
  font-size: 18px;
  font-weight: 600;
  color: var(--token-D1V5iINMo);
  border-radius: 6px;
  padding: 12px 24px;
  border-width: 0px;
  border-style: solid;
}
.title {
  line-height: var(--token-wmteqkiSA);
}
.featureDetailHome__jhAFd:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.anOpenLaptopDisplayingPlaceitsEditorWithApparelMockupsSurroundingItHighlightingTheEaseOfEditingMockups:global(
    .__wab_instance
  ) {
  width: 640px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.htmlVideo___2WpwM:global(.__wab_instance) {
  width: 640px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.link__yImIm {
  position: relative;
  background: #f19a98;
  font-size: 18px;
  font-weight: 600;
  color: var(--token-D1V5iINMo);
  border-radius: 6px;
  padding: 12px 24px;
  border-width: 0px;
  border-style: solid;
}
.title2 {
  line-height: var(--token-wmteqkiSA);
}
.featureDetailHome__p6X8:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.aVideoGameControllerFilledWithPurpleAndNeonGamingDesignTemplatesShowcasingPlaceitByEnvatosCustomizationOptions:global(
    .__wab_instance
  ) {
  width: 640px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.htmlVideo__bxM94:global(.__wab_instance) {
  width: 640px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.link__r7Md5 {
  position: relative;
  background: #8e59cf;
  font-size: 18px;
  font-weight: 600;
  color: var(--token-D1V5iINMo);
  border-radius: 6px;
  padding: 12px 24px;
  border-width: 0px;
  border-style: solid;
}
.title3 {
  line-height: var(--token-wmteqkiSA);
}
.featureDetailHome__sBr0:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.fiveCustomizableLogosInVariousColorsAndStylesUsingThePlaceitByEnvatoLogoMakerDemonstratingEasyFontTextColorAndGraphicAdjustments:global(
    .__wab_instance
  ) {
  width: 640px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.htmlVideo__mjyR:global(.__wab_instance) {
  width: 640px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.link__x46Qz {
  position: relative;
  background: #78d0cb;
  font-size: 18px;
  font-weight: 600;
  color: var(--token-D1V5iINMo);
  border-radius: 6px;
  padding: 12px 24px;
  border-width: 0px;
  border-style: solid;
}
.title4 {
  line-height: var(--token-wmteqkiSA);
}
._017CtaBlock:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-bottom: 40px;
}
.seoTag:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
