.container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.marketingLists {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: var(--token-omGhEM8OI) var(--token-TCakqYrfy);
}
.marketingLists > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - var(--token-u8uQ-APga));
  width: calc(100% + var(--token-u8uQ-APga));
  margin-top: calc(0px - var(--token-TCakqYrfy));
  height: calc(100% + var(--token-TCakqYrfy));
}
.marketingLists > :global(.__wab_flex-container) > *,
.marketingLists > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.marketingLists > :global(.__wab_flex-container) > picture > img,
.marketingLists
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-u8uQ-APga);
  margin-top: var(--token-TCakqYrfy);
}
@media (min-width: 1024px) {
  .marketingLists > :global(.__wab_flex-container) {
    flex-wrap: nowrap;
    align-content: stretch;
    align-items: flex-start;
  }
}
@media (min-width: 1280px) {
  .marketingLists > :global(.__wab_flex-container) {
    margin-left: calc(0px - var(--token-TCakqYrfy));
    width: calc(100% + var(--token-TCakqYrfy));
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1280px) {
  .marketingLists > :global(.__wab_flex-container) > *,
  .marketingLists > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .marketingLists > :global(.__wab_flex-container) > picture > img,
  .marketingLists
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-TCakqYrfy);
    margin-top: 0px;
  }
}
@media (min-width: 1900px) {
  .marketingLists {
    margin: 0px auto auto;
  }
}
.link___6QOhZ {
  display: flex;
  width: 47%;
}
@media (min-width: 600px) {
  .link___6QOhZ {
    width: 48%;
  }
}
@media (min-width: 900px) {
  .link___6QOhZ {
    width: 32%;
  }
}
@media (min-width: 1024px) {
  .link___6QOhZ {
    width: 100%;
    min-width: 0;
  }
}
.clickEventReporter__dbEmw:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  box-shadow: 0px 3px 10px 0px #0000001a;
  position: relative;
  min-width: 0;
  border-radius: 12px;
}
.img__k2Pag {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 426px;
}
.img__k2Pag > picture > img {
  object-fit: cover;
}
.htmlVideo__of7Vk:global(.__wab_instance) {
  width: 426px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.freeBox__itVPj {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-height: 66px;
  min-width: 0;
  padding: var(--token-u8uQ-APga) 12px var(--token-TCakqYrfy);
}
.text__cydNt {
  color: var(--token-KSYikVdQL);
  font-family: var(--token-PsTTGOOvU);
  font-weight: 700;
  font-size: var(--token-jFlfsKM8_wXg);
  line-height: var(--token-MHxx4_Rh4);
  padding-left: 0px;
}
.link__aJlvp {
  display: flex;
  width: 47%;
}
@media (min-width: 600px) {
  .link__aJlvp {
    width: 48%;
  }
}
@media (min-width: 900px) {
  .link__aJlvp {
    width: 32%;
  }
}
@media (min-width: 1024px) {
  .link__aJlvp {
    width: 100%;
    min-width: 0;
  }
}
.clickEventReporter___9IOtf:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.card2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  box-shadow: 0px 3px 10px 0px #0000001a;
  position: relative;
  min-width: 0;
  border-radius: 12px;
}
.img__pvHu8 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 426px;
}
.img__pvHu8 > picture > img {
  object-fit: cover;
}
.htmlVideo___1VdIy:global(.__wab_instance) {
  width: 426px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.freeBox__bE3Qs {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-height: 66px;
  min-width: 0;
  padding: var(--token-u8uQ-APga) 12px var(--token-TCakqYrfy);
}
.text__a1QPe {
  color: var(--token-KSYikVdQL);
  font-family: var(--token-PsTTGOOvU);
  font-weight: 700;
  font-size: var(--token-jFlfsKM8_wXg);
  line-height: var(--token-MHxx4_Rh4);
}
.link__kfkKd {
  display: flex;
  width: 47%;
}
@media (min-width: 600px) {
  .link__kfkKd {
    width: 48%;
  }
}
@media (min-width: 900px) {
  .link__kfkKd {
    width: 32%;
  }
}
@media (min-width: 1024px) {
  .link__kfkKd {
    width: 100%;
    min-width: 0;
  }
}
.clickEventReporter__ud7A4:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.card4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  box-shadow: 0px 3px 10px 0px #0000001a;
  position: relative;
  min-width: 0;
  border-radius: 12px;
}
.img___7GNTp {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 426px;
}
.img___7GNTp > picture > img {
  object-fit: cover;
}
.htmlVideo__eg8Ls:global(.__wab_instance) {
  width: 426px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.freeBox__ovzA {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  min-height: 66px;
  min-width: 0;
  padding: var(--token-u8uQ-APga) 12px var(--token-TCakqYrfy);
}
.span {
  color: var(--token-KSYikVdQL);
  font-family: var(--token-PsTTGOOvU);
  font-weight: 700;
  font-size: var(--token-jFlfsKM8_wXg);
  line-height: var(--token-MHxx4_Rh4);
  white-space: pre-wrap;
  text-align: left;
  width: 100%;
  min-width: 0;
}
.link__pkOa3 {
  display: flex;
  width: 47%;
}
@media (min-width: 600px) {
  .link__pkOa3 {
    width: 48%;
  }
}
@media (min-width: 900px) {
  .link__pkOa3 {
    width: 32%;
  }
}
@media (min-width: 1024px) {
  .link__pkOa3 {
    width: 100%;
    min-width: 0;
  }
}
.clickEventReporter__gvc0U:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.card3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  box-shadow: 0px 3px 10px 0px #0000001a;
  position: relative;
  min-width: 0;
  border-radius: 12px;
}
.img__rWcJ8 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 426px;
}
.img__rWcJ8 > picture > img {
  object-fit: cover;
}
.htmlVideo__pb6Qv:global(.__wab_instance) {
  width: 426px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.freeBox__br4 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-height: 66px;
  min-width: 0;
  padding: var(--token-u8uQ-APga) 12px var(--token-TCakqYrfy);
}
.text___5Zol4 {
  color: var(--token-KSYikVdQL);
  font-family: var(--token-PsTTGOOvU);
  font-weight: 700;
  font-size: var(--token-jFlfsKM8_wXg);
  line-height: var(--token-MHxx4_Rh4);
}
.link__yaOcl {
  display: flex;
  width: 47%;
}
@media (min-width: 600px) {
  .link__yaOcl {
    width: 48%;
  }
}
@media (min-width: 900px) {
  .link__yaOcl {
    width: 32%;
  }
}
@media (min-width: 1024px) {
  .link__yaOcl {
    width: 100%;
    min-width: 0;
  }
}
.clickEventReporter__h9P6K:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.card5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  box-shadow: 0px 3px 10px 0px #0000001a;
  position: relative;
  min-width: 0;
  border-radius: 12px;
}
.img__kYNL {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 426px;
}
.img__kYNL > picture > img {
  object-fit: cover;
}
.htmlVideo__x57Hb:global(.__wab_instance) {
  width: 426px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.freeBox___5ZfWr {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-height: 66px;
  min-width: 0;
  padding: var(--token-u8uQ-APga) 12px var(--token-TCakqYrfy);
}
.text__gr9Rh {
  color: var(--token-KSYikVdQL);
  font-family: var(--token-PsTTGOOvU);
  font-weight: 700;
  font-size: var(--token-jFlfsKM8_wXg);
  line-height: var(--token-MHxx4_Rh4);
  padding-right: 0px;
}
.link___0VpVf {
  display: flex;
  width: 47%;
}
@media (min-width: 600px) {
  .link___0VpVf {
    width: 48%;
  }
}
@media (min-width: 900px) {
  .link___0VpVf {
    width: 32%;
  }
}
@media (min-width: 1024px) {
  .link___0VpVf {
    width: 100%;
    min-width: 0;
  }
}
.clickEventReporter__qzJT:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.card6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  box-shadow: 0px 3px 10px 0px #0000001a;
  position: relative;
  min-width: 0;
  border-radius: 12px;
}
.img__aM7S {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 426px;
}
.img__aM7S > picture > img {
  object-fit: cover;
}
.htmlVideo__nott5:global(.__wab_instance) {
  width: 426px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.freeBox__kAvSb {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-height: 66px;
  min-width: 0;
  padding: var(--token-u8uQ-APga) 12px var(--token-TCakqYrfy);
}
.text__hr9O8 {
  color: var(--token-KSYikVdQL);
  font-family: var(--token-PsTTGOOvU);
  font-weight: 700;
  font-size: var(--token-jFlfsKM8_wXg);
  line-height: var(--token-MHxx4_Rh4);
}
