.root {
  background: #8800f4;
  position: relative;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  pointer-events: none;
  box-shadow: 0px 2px 4px 0px #0000002e, 0px 8px 8px 0px #00000029,
    0px 17px 10px 0px #00000017, 0px 30px 12px 0px #00000008,
    0px 47px 13px 0px transparent;
  line-height: 20.11px;
  border-radius: 6px;
  padding: 8px 16px;
  border: 1px none #8800f4;
}
@media (min-width: 600px) {
  .root {
    padding: 10px 14px;
  }
}
@media (min-width: 900px) {
  .root {
    padding: 10px 14px;
  }
}
@media (min-width: 1024px) {
  .root {
    font-weight: 400;
    font-size: 20px;
    height: 49px;
    line-height: 25.14px;
    padding: 12px 24px;
  }
}
.root:hover {
  background: #662e9b;
  padding-top: 8px;
  padding-right: 16px;
  padding-left: 16px;
  color: #ffffff;
  pointer-events: none;
  box-shadow: 0px 2px 4px 0px #0000002e, 0px 8px 8px 0px #00000029,
    0px 17px 10px 0px #00000017, 0px 30px 12px 0px #00000008,
    0px 47px 13px 0px transparent;
  height: auto;
  border-width: 1px;
  border-color: #662e9b;
}
