.root {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  flex-shrink: 0;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 600px) {
  .root {
    overflow: hidden;
  }
}
@media (min-width: 900px) {
  .root {
    height: auto;
  }
}
.mainContainer2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: var(--token-cTiaoKBgu);
  padding-right: 0px;
  padding-top: 0px;
  background: var(--token-TSgy6DyhAI60);
  min-width: 0;
  border-bottom: 1px solid var(--token-wrFJtVAO5);
}
@media (min-width: 600px) {
  .mainContainer2 {
    justify-content: center;
    align-items: center;
    padding: 0px 0px 0px var(--token-cTiaoKBgu);
  }
}
@media (min-width: 900px) {
  .mainContainer2 {
    height: 240px;
    background: var(--token-TSgy6DyhAI60);
  }
}
@media (min-width: 1024px) {
  .mainContainer2 {
    height: 375px;
    padding: 0px 0px 0px var(--token-cTiaoKBgu);
  }
}
@media (min-width: 1536px) {
  .mainContainer2 {
    height: 375px;
    max-width: 100%;
  }
}
@media (min-width: 1900px) {
  .mainContainer2 {
    padding-left: 100px;
    max-width: 100%;
    height: 375px;
  }
}
.mainContainer2global__112720241500120420240500_override {
  background: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0px 0px 0px var(--token-cTiaoKBgu);
}
@media (min-width: 1024px) {
  .mainContainer2global__112720241500120420240500_override {
    padding-left: 80px;
  }
}
@media (min-width: 900px) {
  .mainContainer2global_ctaLevel_monthly_global__112720241500120420240500_override {
    padding-left: 40px;
  }
}
@media (min-width: 1024px) {
  .mainContainer2global_ctaLevel_monthly_global__112720241500120420240500_override {
    padding-left: 80px;
  }
}
@media (min-width: 1536px) {
  .mainContainer2global_ctaLevel_monthly_global__112720241500120420240500_override {
    padding-left: 80px;
  }
}
.mainContainer2global_ctaLevel_regular_global_region_geo1_global__112720241500120420240500_override {
  display: none;
}
.mainContainer2global_ctaLevel_regular_global_region_geo2_global__112720241500120420240500_override {
  display: none;
}
.mainContainer2global_ctaLevel_visitor_global_region_geo1_global__112720241500120420240500_override {
  display: none;
}
.mainContainer2global_ctaLevel_regular_global_region_geo3_global__112720241500120420240500_override {
  display: none;
}
.mainContainer2global_region_geo2_global_ctaLevel_visitor_global__112720241500120420240500_override {
  display: none;
}
.mainContainer2global_region_geo4_global_ctaLevel_regular_global__112720241500120420240500_override {
  display: none;
}
.mainContainer2global_region_geo3_global_ctaLevel_visitor_global__112720241500120420240500_override {
  display: none;
}
.mainContainer2global_region_geo4_global_ctaLevel_visitor_global__112720241500120420240500_override {
  display: none;
}
@media (min-width: 1536px) {
  .mainContainer2global_region_geo1_global_ctaLevel_monthly_global__112720241500120420240500_override {
    padding-left: 80px;
  }
}
@media (min-width: 1536px) {
  .mainContainer2global_region_geo2_global_ctaLevel_monthly_global__112720241500120420240500_override {
    padding-left: 80px;
  }
}
@media (min-width: 1536px) {
  .mainContainer2global_region_geo3_global_ctaLevel_monthly_global__112720241500120420240500_override {
    padding-left: 82px;
  }
}
@media (min-width: 1536px) {
  .mainContainer2global_region_geo4_global_ctaLevel_monthly_global__112720241500120420240500_override {
    padding-left: 80px;
  }
}
@media (min-width: 1536px) {
  .mainContainer2global_region_geoin_global_ctaLevel_monthly_global__112720241500120420240500_override {
    padding-left: 80px;
  }
}
@media (min-width: 1536px) {
  .mainContainer2global_region_geobr_global_ctaLevel_monthly_global__112720241500120420240500_override {
    padding-left: 80px;
  }
}
.texts2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 60%;
  height: 100%;
  max-width: 100%;
  background: linear-gradient(
      var(--token-TSgy6DyhAI60),
      var(--token-TSgy6DyhAI60)
    ),
    var(--token-PVSKgbZylV9Q);
  min-height: 0;
  padding: var(--token-TCakqYrfy) 0px;
}
.texts2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.texts2 > :global(.__wab_flex-container) > *,
.texts2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.texts2 > :global(.__wab_flex-container) > picture > img,
.texts2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
@media (min-width: 600px) {
  .texts2 {
    width: 50%;
    height: 100%;
    min-height: 0;
  }
}
@media (min-width: 600px) {
  .texts2 > :global(.__wab_flex-container) {
    align-items: flex-start;
    justify-content: center;
    min-height: 0;
  }
}
@media (min-width: 900px) {
  .texts2 {
    width: 50%;
    background: var(--token-TSgy6DyhAI60);
    height: 100%;
    min-height: 0;
  }
}
@media (min-width: 900px) {
  .texts2 > :global(.__wab_flex-container) {
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 900px) {
  .texts2 > :global(.__wab_flex-container) > *,
  .texts2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .texts2 > :global(.__wab_flex-container) > picture > img,
  .texts2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 1024px) {
  .texts2 {
    background: none;
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .texts2 {
    width: 55%;
  }
}
@media (min-width: 1536px) {
  .texts2 {
    width: 55%;
    padding: 0px 0px 0px var(--token-cTiaoKBgu);
  }
}
@media (min-width: 1536px) {
  .texts2 > :global(.__wab_flex-container) {
    align-items: flex-start;
    justify-content: center;
  }
}
@media (min-width: 1900px) {
  .texts2 {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 1900px) {
  .texts2 > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.texts2global_ctaLevel_annual {
  background: none;
}
.texts2global_ctaLevel_annual > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 6px);
  height: calc(100% + 6px);
}
.texts2global_ctaLevel_annual > :global(.__wab_flex-container) > *,
.texts2global_ctaLevel_annual
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.texts2global_ctaLevel_annual > :global(.__wab_flex-container) > picture > img,
.texts2global_ctaLevel_annual
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 6px;
}
.texts2global__112720241500120420240500_override {
  width: 60%;
  background: var(--token-D1V5iINMo);
}
.texts2global__112720241500120420240500_override
  > :global(.__wab_flex-container) {
  align-items: flex-start;
  justify-content: center;
}
@media (min-width: 1024px) {
  .texts2global__112720241500120420240500_override {
    width: 100%;
    margin-right: 0px;
    height: auto;
    max-width: 100%;
    background: #ffffff;
    flex-direction: column;
    min-width: 0;
    padding: var(--token-TCakqYrfy) 0px;
  }
}
@media (min-width: 1024px) {
  .texts2global__112720241500120420240500_override
    > :global(.__wab_flex-container) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 1024px) {
  .texts2global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > *,
  .texts2global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .texts2global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > picture
    > img,
  .texts2global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 1280px) {
  .texts2global_ctaLevel_annual_global__112720241500120420240500_override
    > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 6px);
    height: calc(100% + 6px);
  }
}
@media (min-width: 1280px) {
  .texts2global_ctaLevel_annual_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > *,
  .texts2global_ctaLevel_annual_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .texts2global_ctaLevel_annual_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > picture
    > img,
  .texts2global_ctaLevel_annual_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 6px;
  }
}
.text {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: var(--token-wmteqkiSA);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  letter-spacing: 0px;
  min-width: 0;
}
@media (min-width: 600px) {
  .text {
    font-size: 24px;
  }
}
@media (min-width: 900px) {
  .text {
    color: #ffffff;
    font-size: 24px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
@media (min-width: 1024px) {
  .text {
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: var(--token-wmteqkiSA);
    color: #ffffff;
  }
}
@media (min-width: 1280px) {
  .text {
    max-width: 640px;
  }
}
@media (min-width: 1536px) {
  .text {
    font-size: 40px;
  }
}
.textglobal__112720241500120420240500_override {
  font-weight: 700;
  font-size: var(--token-_MOw8VJgR);
  line-height: var(--token-wmteqkiSA);
  color: var(--token-aLKgcASGV);
  text-align: left;
}
@media (min-width: 1024px) {
  .textglobal__112720241500120420240500_override {
    font-size: 40px;
    text-align: left;
  }
}
@media (min-width: 1536px) {
  .textglobal__112720241500120420240500_override {
    font-size: var(--token-hOqCuytDszPG);
  }
}
@media (min-width: 900px) {
  .textglobal_ctaLevel_monthly_global__112720241500120420240500_override {
    font-size: 32px;
  }
}
@media (min-width: 1024px) {
  .textglobal_ctaLevel_monthly_global__112720241500120420240500_override {
    font-size: 40px;
  }
}
@media (min-width: 1536px) {
  .textglobal_ctaLevel_monthly_global__112720241500120420240500_override {
    font-size: 48px;
  }
}
@media (min-width: 1280px) {
  .textglobal_ctaLevel_annual_global__112720241500120420240500_override {
    text-align: left;
  }
}
@media (min-width: 1536px) {
  .textglobal_region_geo1_global_ctaLevel_monthly_global__112720241500120420240500_override {
    font-size: var(--token-hOqCuytDszPG);
  }
}
@media (min-width: 1536px) {
  .textglobal_region_geo2_global_ctaLevel_monthly_global__112720241500120420240500_override {
    font-size: var(--token-hOqCuytDszPG);
    color: #303030;
  }
}
@media (min-width: 1536px) {
  .textglobal_region_geo3_global_ctaLevel_monthly_global__112720241500120420240500_override {
    font-size: var(--token-hOqCuytDszPG);
  }
}
@media (min-width: 1536px) {
  .textglobal_region_geo4_global_ctaLevel_monthly_global__112720241500120420240500_override {
    font-size: var(--token-hOqCuytDszPG);
  }
}
@media (min-width: 1536px) {
  .textglobal_region_geoin_global_ctaLevel_monthly_global__112720241500120420240500_override {
    font-size: 48px;
  }
}
@media (min-width: 1536px) {
  .textglobal_region_geobr_global_ctaLevel_monthly_global__112720241500120420240500_override {
    font-size: 48px;
  }
}
.h1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 16px;
  color: var(--token-D1V5iINMo);
  font-weight: 400;
  text-align: left;
  letter-spacing: normal;
  user-select: none;
  padding-left: 0px;
  line-height: 17.6px;
  min-width: 0;
}
@media (min-width: 600px) {
  .h1 {
    line-height: var(--token-wmteqkiSA);
  }
}
@media (min-width: 900px) {
  .h1 {
    display: block;
  }
}
@media (min-width: 1024px) {
  .h1 {
    padding: 0px 0px 4px;
  }
}
@media (min-width: 1280px) {
  .h1 {
    padding-bottom: 4px;
    font-size: 22px;
  }
}
@media (min-width: 1536px) {
  .h1 {
    padding-bottom: 4px;
    font-size: 24px;
  }
}
.h1global__112720241500120420240500_override {
  display: none;
}
@media (min-width: 1024px) {
  .h1global__112720241500120420240500_override {
    display: block;
  }
}
@media (min-width: 1536px) {
  .h1global__112720241500120420240500_override {
    display: block;
  }
}
.buttons2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 0px;
}
.buttons2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.buttons2 > :global(.__wab_flex-container) > *,
.buttons2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttons2 > :global(.__wab_flex-container) > picture > img,
.buttons2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
@media (min-width: 600px) {
  .buttons2 > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 600px) {
  .buttons2 > :global(.__wab_flex-container) > *,
  .buttons2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .buttons2 > :global(.__wab_flex-container) > picture > img,
  .buttons2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 900px) {
  .buttons2 {
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    width: auto;
    height: auto;
  }
}
@media (min-width: 900px) {
  .buttons2 > :global(.__wab_flex-container) {
    flex-direction: row;
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 900px) {
  .buttons2 > :global(.__wab_flex-container) > *,
  .buttons2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .buttons2 > :global(.__wab_flex-container) > picture > img,
  .buttons2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
@media (min-width: 1024px) {
  .buttons2 {
    padding-top: 0px;
  }
}
@media (min-width: 1024px) {
  .buttons2 > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: center;
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1024px) {
  .buttons2 > :global(.__wab_flex-container) > *,
  .buttons2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .buttons2 > :global(.__wab_flex-container) > picture > img,
  .buttons2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
@media (min-width: 1280px) {
  .buttons2 > :global(.__wab_flex-container) {
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1280px) {
  .buttons2 > :global(.__wab_flex-container) > *,
  .buttons2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .buttons2 > :global(.__wab_flex-container) > picture > img,
  .buttons2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
.buttons2global_ctaLevel_monthly > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.buttons2global_ctaLevel_monthly > :global(.__wab_flex-container) > *,
.buttons2global_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.buttons2global_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > picture
  > img,
.buttons2global_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 8px;
}
.buttons2global_ctaLevel_annual > :global(.__wab_flex-container) {
  align-items: flex-start;
  justify-content: center;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.buttons2global_ctaLevel_annual > :global(.__wab_flex-container) > *,
.buttons2global_ctaLevel_annual
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.buttons2global_ctaLevel_annual
  > :global(.__wab_flex-container)
  > picture
  > img,
.buttons2global_ctaLevel_annual
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.buttons2global_ctaLevel_pastdue > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.buttons2global_ctaLevel_pastdue > :global(.__wab_flex-container) > *,
.buttons2global_ctaLevel_pastdue
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.buttons2global_ctaLevel_pastdue
  > :global(.__wab_flex-container)
  > picture
  > img,
.buttons2global_ctaLevel_pastdue
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 4px;
}
.buttons2global__112720241500120420240500_override
  > :global(.__wab_flex-container) {
  align-items: flex-start;
  justify-content: flex-start;
}
@media (min-width: 1024px) {
  .buttons2global_ctaLevel_monthly > :global(.__wab_flex-container) {
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1024px) {
  .buttons2global_ctaLevel_monthly > :global(.__wab_flex-container) > *,
  .buttons2global_ctaLevel_monthly
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .buttons2global_ctaLevel_monthly
    > :global(.__wab_flex-container)
    > picture
    > img,
  .buttons2global_ctaLevel_monthly
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
@media (min-width: 1024px) {
  .buttons2global_ctaLevel_pastdue > :global(.__wab_flex-container) {
    flex-wrap: wrap;
    align-items: stretch;
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
  }
}
@media (min-width: 1024px) {
  .buttons2global_ctaLevel_pastdue > :global(.__wab_flex-container) > *,
  .buttons2global_ctaLevel_pastdue
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .buttons2global_ctaLevel_pastdue
    > :global(.__wab_flex-container)
    > picture
    > img,
  .buttons2global_ctaLevel_pastdue
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
  }
}
.buttons2global_ctaLevel_annual_global__112720241500120420240500_override
  > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.buttons2global_ctaLevel_annual_global__112720241500120420240500_override
  > :global(.__wab_flex-container)
  > *,
.buttons2global_ctaLevel_annual_global__112720241500120420240500_override
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.buttons2global_ctaLevel_annual_global__112720241500120420240500_override
  > :global(.__wab_flex-container)
  > picture
  > img,
.buttons2global_ctaLevel_annual_global__112720241500120420240500_override
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 8px;
}
@media (min-width: 900px) {
  .buttons2global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container) {
    margin-left: calc(0px - 6px);
    width: calc(100% + 6px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 900px) {
  .buttons2global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > *,
  .buttons2global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .buttons2global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > picture
    > img,
  .buttons2global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 6px;
    margin-top: 0px;
  }
}
@media (min-width: 1024px) {
  .buttons2global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container) {
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1024px) {
  .buttons2global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > *,
  .buttons2global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .buttons2global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > picture
    > img,
  .buttons2global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
@media (min-width: 1536px) {
  .buttons2global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container) {
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1536px) {
  .buttons2global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > *,
  .buttons2global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .buttons2global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > picture
    > img,
  .buttons2global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
@media (min-width: 1536px) {
  .buttons2global_region_geo1_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container) {
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1536px) {
  .buttons2global_region_geo1_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > *,
  .buttons2global_region_geo1_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .buttons2global_region_geo1_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > picture
    > img,
  .buttons2global_region_geo1_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
@media (min-width: 1536px) {
  .buttons2global_region_geo2_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container) {
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1536px) {
  .buttons2global_region_geo2_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > *,
  .buttons2global_region_geo2_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .buttons2global_region_geo2_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > picture
    > img,
  .buttons2global_region_geo2_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
@media (min-width: 1536px) {
  .buttons2global_region_geo3_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container) {
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1536px) {
  .buttons2global_region_geo3_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > *,
  .buttons2global_region_geo3_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .buttons2global_region_geo3_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > picture
    > img,
  .buttons2global_region_geo3_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
@media (min-width: 1536px) {
  .buttons2global_region_geo4_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container) {
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1536px) {
  .buttons2global_region_geo4_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > *,
  .buttons2global_region_geo4_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .buttons2global_region_geo4_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > picture
    > img,
  .buttons2global_region_geo4_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
@media (min-width: 1536px) {
  .buttons2global_region_geoin_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container) {
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1536px) {
  .buttons2global_region_geoin_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > *,
  .buttons2global_region_geoin_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .buttons2global_region_geoin_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > picture
    > img,
  .buttons2global_region_geoin_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
@media (min-width: 1536px) {
  .buttons2global_region_geobr_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container) {
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1536px) {
  .buttons2global_region_geobr_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > *,
  .buttons2global_region_geobr_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .buttons2global_region_geobr_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > picture
    > img,
  .buttons2global_region_geobr_global_ctaLevel_monthly_global__112720241500120420240500_override
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
.link__mxTuM {
  display: flex;
}
.linkglobal_ctaLevel_annual__mxTuMolFmy {
  display: none;
}
.clickEventReporter__ux4YE:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.clickEventReporterglobal_ctaLevel_annual__ux4YEolFmy:global(.__wab_instance) {
  display: none;
}
.clickEventReporterglobal_ctaLevel_monthly_global__112720241500120420240500_override__ux4YEu1Vk1VTogJ:global(
    .__wab_instance
  ) {
  display: flex;
}
.clickEventReporterglobal_ctaLevel_annual_global__112720241500120420240500_override__ux4YEolFmyVTogJ:global(
    .__wab_instance
  ) {
  display: none;
}
.subscribeNow {
  display: flex;
}
.btnWomens:global(.__wab_instance) {
  max-width: 100%;
}
.goUnlimitedBtn2 {
  position: relative;
  margin-bottom: 8px;
  color: #ffffff;
  display: none;
}
@media (min-width: 600px) {
  .goUnlimitedBtn2 {
    display: none;
  }
}
@media (min-width: 1280px) {
  .goUnlimitedBtn2 {
    margin-bottom: 0px;
  }
}
.goUnlimitedBtn2global_ctaLevel_monthly {
  color: #000000;
  font-size: var(--token-jFlfsKM8_wXg);
  display: none;
}
.goUnlimitedBtn2global_ctaLevel_annual {
  display: none;
}
.goUnlimitedBtn2global_ctaLevel_pastdue {
  display: flex;
}
.goUnlimitedBtn2global__112720241500120420240500_override {
  display: none;
}
.link__ggBLr {
  color: #3dbe00;
  background: #ffffff;
  display: none;
}
@media (min-width: 900px) {
  .link__ggBLr {
    color: #ffffff;
  }
}
.linkglobal_ctaLevel_monthly__ggBLru1Vk1 {
  color: #ffffff;
}
.linkglobal_ctaLevel_monthly__ggBLru1Vk1 > :global(.__wab_flex-container) {
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.linkglobal_ctaLevel_monthly__ggBLru1Vk1 > :global(.__wab_flex-container) > *,
.linkglobal_ctaLevel_monthly__ggBLru1Vk1
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.linkglobal_ctaLevel_monthly__ggBLru1Vk1
  > :global(.__wab_flex-container)
  > picture
  > img,
.linkglobal_ctaLevel_monthly__ggBLru1Vk1
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.linkglobal_ctaLevel_annual__ggBLrOlFmy {
  display: flex;
}
.linkglobal__021020252100030720251600_override__ggBLruZp9N {
  padding-left: 0px;
  color: #ffffff;
  display: none;
}
.linkglobal_ctaLevel_annual_global__112720241500120420240500_override__ggBLrOlFmyVTogJ {
  display: none;
}
.btn2Home:global(.__wab_instance) {
  transition-property: all;
  transition-duration: 1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
@media (min-width: 900px) {
  .btn2Home:global(.__wab_instance) {
    width: auto;
    height: auto;
  }
}
.btn2Homeglobal_ctaLevel_annual:global(.__wab_instance) {
  display: block;
}
.btn2Homeglobal_ctaLevel_regular:global(.__wab_instance) {
  height: 100%;
}
.btn2Homeglobal__112720241500120420240500_override:global(.__wab_instance) {
  display: block;
}
.btn2Homeglobal__021020252100030720251600_override:global(.__wab_instance) {
  display: none;
}
.clickEventReporter___6CAs:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  display: none;
}
.clickEventReporterglobal_ctaLevel_annual___6CAsOlFmy:global(.__wab_instance) {
  display: flex;
}
.clickEventReporterglobal__112720241500120420240500_override___6CAsVTogJ:global(
    .__wab_instance
  ) {
  display: flex;
}
.discoverCollectionBtn2 {
  color: #ffffff;
  display: none;
}
@media (min-width: 600px) {
  .discoverCollectionBtn2 {
    display: none;
  }
}
.discoverCollectionBtn2global_ctaLevel_monthly {
  color: #ffffff;
}
.discoverCollectionBtn2global_ctaLevel_monthly
  > :global(.__wab_flex-container) {
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.discoverCollectionBtn2global_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > *,
.discoverCollectionBtn2global_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.discoverCollectionBtn2global_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > picture
  > img,
.discoverCollectionBtn2global_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.discoverCollectionBtn2global_ctaLevel_annual {
  display: flex;
}
.discoverCollectionBtn2global_ctaLevel_regular {
  color: var(--token-jgohepLVeKvh);
}
.discoverCollectionBtn2global__112720241500120420240500_override {
  display: flex;
}
.btn1Home:global(.__wab_instance) {
  max-width: 100%;
  transition-property: all;
  transition-duration: 1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.link__mPOm {
  margin-top: -8px;
  display: none;
}
@media (min-width: 900px) {
  .link__mPOm {
    margin-top: 0px;
  }
}
@media (min-width: 1024px) {
  .link__mPOm {
    margin-top: -8px;
  }
}
@media (min-width: 1280px) {
  .link__mPOm {
    margin-top: 0px;
  }
}
.linkglobal_ctaLevel_annual__mPOmOlFmy {
  display: none;
}
.linkglobal__112720241500120420240500_override__mPOmvTogJ {
  display: none;
}
.imgs2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 40%;
  height: 100%;
  max-width: 100%;
  min-height: 0;
  padding: 0px;
}
@media (min-width: 600px) {
  .imgs2 {
    width: 50%;
    align-items: flex-end;
    justify-content: center;
  }
}
@media (min-width: 900px) {
  .imgs2 {
    width: 50%;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
  }
}
@media (min-width: 1024px) {
  .imgs2 {
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .imgs2 {
    width: 45%;
  }
}
@media (min-width: 1536px) {
  .imgs2 {
    width: 45%;
    justify-content: center;
    align-items: flex-start;
  }
}
@media (min-width: 1900px) {
  .imgs2 {
    width: 100%;
    min-width: 0;
  }
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  align-self: auto;
  object-position: left center;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.img > picture > img {
  object-fit: cover;
  object-position: left center;
}
@media (min-width: 600px) {
  .img {
    object-position: left center;
    object-fit: cover;
  }
}
@media (min-width: 600px) {
  .img > picture > img {
    object-fit: cover;
    object-position: left center;
  }
}
@media (min-width: 900px) {
  .img {
    align-self: auto;
    object-fit: cover;
    object-position: left top;
  }
}
@media (min-width: 900px) {
  .img > picture > img {
    object-fit: cover;
    object-position: left top;
  }
}
@media (min-width: 1024px) {
  .img {
    object-fit: cover;
  }
}
@media (min-width: 1024px) {
  .img > picture > img {
    object-fit: cover;
  }
}
@media (min-width: 1280px) {
  .img {
    object-position: right top;
  }
}
@media (min-width: 1280px) {
  .img > picture > img {
    object-position: right top;
  }
}
@media (min-width: 1536px) {
  .img {
    object-position: right top;
  }
}
@media (min-width: 1536px) {
  .img > picture > img {
    object-position: right top;
  }
}
@media (min-width: 1900px) {
  .img {
    object-fit: cover;
    object-position: center top;
  }
}
@media (min-width: 1900px) {
  .img > picture > img {
    object-fit: cover;
    object-position: center top;
  }
}
@media (min-width: 1024px) {
  .imgglobal__112720241500120420240500_override {
    width: 472px;
    margin-left: 0px;
    flex-shrink: 0;
  }
}
@media (min-width: 1280px) {
  .imgglobal__112720241500120420240500_override {
    width: 654px;
    flex-shrink: 0;
  }
}
@media (min-width: 1536px) {
  .imgglobal__112720241500120420240500_override {
    width: 819px;
    object-position: left center;
    flex-shrink: 0;
  }
}
@media (min-width: 1536px) {
  .imgglobal__112720241500120420240500_override > picture > img {
    object-position: left center;
  }
}
