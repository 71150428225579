.root {
  background: #3dbe00;
  position: relative;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  height: auto;
  line-height: 1.5;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  border-radius: 6px;
  padding: 8px 16px;
  border: 1px solid #3dbe00;
}
@media (min-width: 600px) {
  .root {
    background: #3dbe00;
    font-size: 16px;
    padding: 8px 16px;
    border-color: #3dbe00;
  }
}
@media (min-width: 900px) {
  .root {
    background: #3dbe00;
    padding: 8px 16px;
  }
}
@media (min-width: 1024px) {
  .root {
    height: var(--token-rYh-ACW-A);
    font-weight: 400;
    font-size: 16px;
    padding: 8px 16px;
  }
}
@media (min-width: 1280px) {
  .root {
    line-height: 22.63px;
  }
}
@media (min-width: 1536px) {
  .root {
    background: #3dbe00;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
    line-height: 25.14px;
    color: var(--token-41VRhDKfihhY);
    font-size: 18px;
    border-radius: 6px;
    padding: 8px 20px;
    border: 1px solid #3dbe00;
  }
}
@media (min-width: 1900px) {
  .root {
    font-size: 16px;
    padding: 8px 20px;
  }
}
.rootglobal_ctaLevel_monthly {
  padding: 8px 16px;
}
.rootglobal_ctaLevel_annual {
  background: #3dbe00;
}
.rootglobal_ctaLevel_regular_global_region_geo1_global__112720241500120420240500_override {
  background: #8800f4;
}
.rootglobal_ctaLevel_regular_global_region_geo2_global__112720241500120420240500_override {
  background: #8800f4;
}
.rootglobal_ctaLevel_regular_global_region_geo3_global__112720241500120420240500_override {
  background: #8800f4;
}
.rootglobal_region_geo4_global_ctaLevel_regular_global__112720241500120420240500_override {
  background: #8800f4;
  box-shadow: 0px 2px 4px 0px #0000002e, 0px 8px 8px 0px #00000029,
    0px 17px 10px 0px #00000017, 0px 30px 12px 0px #00000008,
    0px 47px 13px 0px transparent;
  line-height: 20.11px;
  font-weight: 600;
  border-color: var(--token-D1V5iINMo);
}
@media (min-width: 900px) {
  .rootglobal_ctaLevel_regular_global_region_geo1_global__112720241500120420240500_override {
    text-align: center;
  }
}
.root:hover {
  background: #368b0c;
  padding-top: 8px;
  padding-right: 16px;
  padding-left: 16px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  filter: blur(0px);
  color: #ffffff;
  border-width: 1px;
  border-color: #368b0c;
}
.rootglobal_ctaLevel_monthly:hover {
  text-align: right;
}
